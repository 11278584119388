import Swiper from "swiper";
import {
  Grid,
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
} from "swiper/modules";

// 基本 Swiper 配置工廠函數
const createSwiper = (selector, options) => {
  const element = document.querySelector(selector);
  if (!element) {
    // console.warn(`Swiper element not found: ${selector}`);
    return null;
  }
  return new Swiper(selector, {
    modules: [Navigation, Pagination, Autoplay, Grid, EffectFade],
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    ...options,
  });
};

// 響應式 Swiper 初始化工廠
const createResponsiveSwiper = (selector, instance, maxWidth, options) => {
  const element = document.querySelector(selector);
  if (!element) {
    console.warn(`Responsive Swiper element not found: ${selector}`);
    return null;
  }

  if (window.innerWidth < maxWidth) {
    if (!instance) {
      return createSwiper(selector, options);
    }
  } else if (instance) {
    instance.destroy(true, true);
    return null;
  }
  return instance;
};

// 檢查頁面模板
const isTemplate = (templateClass) =>
  document.body.classList.contains(templateClass);

// 響應式 Swiper 實例
let serviceSwiper = null;
let videoMeasuresSwiper = null;
let creativeProductionSwiper = null;
let socialMediaMeasuresSwiper = null;
let socialMarketingServicesSwiper = null;
let searchEngineOptimizationServicesSwiper = null;
let careersCultureSwiper = null;

function initResponsiveSwipers() {
  // Service Swiper (小於 1024px) - 只在首頁初始化
  if (isTemplate("home")) {
    serviceSwiper = createResponsiveSwiper(
      ".home .servive .swiper-container",
      serviceSwiper,
      1024,
      {
        grid: { rows: 1 },
        slidesPerView: 1.4,
        spaceBetween: 20,
        centeredSlides: true,
        breakpoints: {
          640: {
            slidesPerView: 3,
            centeredSlides: false,
          },
        },
      }
    );
  }

  // Creative Production Swiper (小於 768px)
  if (isTemplate("page-template-creative-production")) {
    creativeProductionSwiper = createResponsiveSwiper(
      ".page-template-creative-production .related_cases .swiper-container",
      creativeProductionSwiper,
      768,
      {
        slidesPerView: 1,
      }
    );
  }

  // Video Measures Swiper (小於 768px)
  if (isTemplate("page-template-digital-ads-video")) {
    videoMeasuresSwiper = createResponsiveSwiper(
      ".page-template-digital-ads-video .measures .swiper-container",
      videoMeasuresSwiper,
      768,
      {
        slidesPerView: 1,
      }
    );
  }

  // Social Media Measures Swiper (小於 768px)
  if (isTemplate("page-template-digital-ads-social-media-ads")) {
    socialMediaMeasuresSwiper = createResponsiveSwiper(
      ".page-template-digital-ads-social-media-ads .measures .swiper-container",
      socialMediaMeasuresSwiper,
      768,
      {
        slidesPerView: 1,
      }
    );
  }

  // Social Marketing Services Swiper (小於 768px)
  if (isTemplate("page-template-social-marketing")) {
    socialMarketingServicesSwiper = createResponsiveSwiper(
      ".page-template-social-marketing .services .swiper-container",
      socialMarketingServicesSwiper,
      768,
      {
        slidesPerView: 1,
      }
    );
  }

  // Search Engine Optimization Services Swiper (小於 768px)
  if (isTemplate("page-template-search-engine-optimization")) {
    searchEngineOptimizationServicesSwiper = createResponsiveSwiper(
      ".page-template-search-engine-optimization .services .swiper-container",
      searchEngineOptimizationServicesSwiper,
      768,
      {
        slidesPerView: 1,
      }
    );
  }

  // Careers Culture Swiper (小於 768px)
  if (isTemplate("page-template-careers")) {
    careersCultureSwiper = createResponsiveSwiper(
      ".page-template-careers .culture .swiper-container",
      careersCultureSwiper,
      768,
      {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      }
    );
  }
}

// 連續輪播配置
const createContinuousSwiper = (selector, options) => {
  return createSwiper(selector, {
    grid: { rows: 1, fill: "row" },
    slidesPerView: 2,
    spaceBetween: 20,
    speed: 3000,
    autoplay: { delay: 0 },
    breakpoints: {
      640: { slidesPerView: 3, speed: 5000 },
      1024: { slidesPerView: 4 },
    },
    ...options,
  });
};

// Fade 效果 Swiper 配置
const createFadeSwiper = (selector, options) => {
  return createSwiper(selector, {
    effect: "fade",
    autoplay: { delay: 5000 },
    fadeEffect: { crossFade: true },
    ...options,
  });
};

// 初始化所有 Swiper
(() => {
  // 事件監聽
  window.addEventListener("load", initResponsiveSwipers);
  window.addEventListener("resize", initResponsiveSwipers);

  // 首頁 Swiper 初始化
  if (isTemplate("home")) {
    // News Topics Swiper
    createSwiper(".home .news_topics .swiper-container", {
      grid: { rows: 1, fill: "row" },
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        640: {
          slidesPerView: 2,
          grid: { rows: 2, fill: "row" },
        },
        1024: { slidesPerView: 4 },
      },
    });

    // 客戶展示 Swiper
    createContinuousSwiper(".home .clients .swiper-container");
  }

  // Digital Ads 頁面 Swiper
  if (isTemplate("page-template-digital-ads")) {
    createContinuousSwiper(
      ".page-template-digital-ads .clients .swiper-container"
    );
    createFadeSwiper(
      ".page-template-digital-ads .case_studies .swiper-container"
    );
  }

  // Digital Ads Search 頁面 Swiper
  if (isTemplate("page-template-digital-ads-search")) {
    createContinuousSwiper(
      ".page-template-digital-ads-search .clients .swiper-container"
    );
  }

  // Digital Ads Display 頁面 Swiper
  if (isTemplate("page-template-digital-ads-display")) {
    createFadeSwiper(
      ".page-template-digital-ads-display .advantages .swiper-container"
    );
    createSwiper(
      ".page-template-digital-ads-display .measures .swiper-container",
      {
        slidesPerView: 1,
      }
    );
  }

  // Search Engine Optimization 頁面 Swiper
  if (isTemplate("page-template-search-engine-optimization")) {
    createFadeSwiper(
      ".page-template-search-engine-optimization .case_studies .swiper-container"
    );
  }
})();

// console.log("Hello, world from the swiper.js file!");
